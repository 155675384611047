import React, { Suspense, useState, useRef, useEffect, useMemo, useCallback, forwardRef } from 'react'
import * as THREE from 'three';
import { Canvas, useFrame, extend, useThree } from 'react-three-fiber';
import { Reflector, CameraShake, OrbitControls, useTexture, Text, Text3D } from '@react-three/drei';
import myFont from './font/SairaSemiCondensed-Regular.ttf';


const useInterval = (callback, delay) => {
    const savedCallback = React.useRef();

    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    React.useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, []);
};

function MatrixRain(props) {

    // const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789                        "
    // const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ                        "
    // const chars = "DATACORRIDOR   あいうえ                     "
    const chars = "00I   "

    const ref = useRef()
    // const [text, setText] = useState(null)
    var text = null
    const rainMesh = useRef()

    const maxChars = props.length
    // const charsArray = chars.split('')

    useInterval(() => {
        // let nutxt = text

        if (text === null) {
            text = []

            for (let i = 0; i < maxChars; i++) {
                text.push(chars.charAt(Math.floor(Math.random() * chars.length)))
            }
        }
        else if (text.length === maxChars) {
            text.shift()
            text.push(chars.charAt(Math.floor(Math.random() * chars.length)))

            for (let i = 0; i < text.length; i++) {
                rainMesh.current.children[i].children[0].text = text[i]
            }
        }
        else
            text.push(chars.charAt(Math.floor(Math.random() * chars.length)))

        if (text && rainMesh.current) {
            for (let i = 0; i < text.length; i++) {
                if (text[i] == 'D' || text[i] == 'A' || text[i] == 'T' || text[i] == 'C' || text[i] == 'O' || text[i] == 'R' || text[i] == 'I')
                    rainMesh.current.children[i].children[0].fillOpacity = props.maxOpacity
                else
                    rainMesh.current.children[i].children[0].fillOpacity *= props.decayOpacity
            }
        }

        // console.log(rainMesh.current)
    }, props.delay)

    // useFrame(({ clock }, delta) => {
    //     const a = clock.getElapsedTime()
    //     const x = ref.current.position.x;
    //     const z = ref.current.position.z;
    //     // if (props.rx)
    //     //     ref.current.rotation.x += props.rx;

    //     ref.current.position.y = Math.sin(0.001 * (x ** 2 + z ** 2 - a * 1000)) * 3 + props.offsets[1];
    // })

    if (props.mode === 'vertical') {
        return <mesh ref={rainMesh} {...props.baseMesh}>{[...Array(maxChars).keys()].map((c) => <mesh
            {...props}
            ref={ref}
            position={[props.position[0], props.position[1] + (c) * props.spacing * (props.reversed ? -1 : 1), props.position[2]]}
        >
            <Text
                
                scale={[props.scale, props.scale, props.scale]}
                color={props.color}
                fillOpacity={props.opacity}
                anchorX={"center"}
                anchorY={"middle"}
                // rotation={props.rotation}
                font={myFont}
            >{0}
                {/* <meshPhysicalMaterial specularColor={props.color} specularIntensity={5} roughness={0.7} transmission={1} thickness={1} /> */}
            </Text>
        </mesh>)}
        </mesh>
    }
    else if (props.mode === 'horizontal') {
        return <mesh ref={rainMesh} {...props.baseMesh}>{[...Array(maxChars).keys()].map((c) => <mesh
            {...props}
            ref={ref}
            position={[props.position[0], props.position[1], props.position[2] + (c) * props.spacing * (props.reversed ? -1 : 1)]}
        >
            <Text
                scale={[props.scale, props.scale, props.scale]}
                color={props.color}
                fillOpacity={props.opacity}
                anchorX={"center"}
                anchorY={"middle"}
                // rotation={props.rotation}
                font={myFont}
            >{0}
                {/* <meshPhysicalMaterial specularColor={props.color} specularIntensity={5} roughness={0.7} transmission={1} thickness={1} /> */}
            </Text>
        </mesh>)}
        </mesh>
    }

}

export default MatrixRain;