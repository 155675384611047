import * as React from 'react';
import { useEffect } from 'react';
import Slide from '@mui/material/Slide';
import Fade from '@mui/material/Fade';
import { alpha, styled } from '@mui/material/styles';
import { Grid, TextField } from '@mui/material';
import { Button } from '@mui/material';
import { cyan, fontStyle } from './App';
import SendIcon from '@mui/icons-material/Send';
import './Forms.css'
import emailjs from '@emailjs/browser';


export const FormStateContext = React.createContext({
    sumbitted: false,
    setSubmitted: () => { }
})

const transitTimeouts = { appear: 450, enter: 350, exit: 350 };


const color = cyan(2);

const CssTextField = styled(TextField)({
    '& label': {
        color: '#868279',
        fontFamily: 'saira semi condensed',

    },
    '& label.Mui-focused': {
        color,
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: 'grey',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: color,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#ca6924',
            color
        },
        '&:hover fieldset': {
            borderColor: 'silver',
            color
        },
        '&.Mui-focused fieldset': {
            borderColor: color,
        },
    },
});

function Forms(props) {


    const formRef = React.useRef();
    const [valid, setValid] = React.useState(false);
    const [submitted, setSubmitted] = React.useState('unsent');

    const [name, setName] = React.useState('');
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const [sname, setSname] = React.useState('');
    const handleSnameChange = (event) => {
        setSname(event.target.value);
    };

    const [company, setCompany] = React.useState('');
    const handleCompanyChange = (event) => {
        setCompany(event.target.value);
    };

    const [email, setEmail] = React.useState('');
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const [country, setCountry] = React.useState('');
    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const [phone, setPhone] = React.useState('');
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    };

    const [message, setMessage] = React.useState('');
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    // function validateFields() {

    // }

    useEffect(() => {
        setValid(name && sname && company && email && country && phone && message ? true : false)
    }, [name, sname, company, email, phone, message, country])

    const sendEmail = () => {
        emailjs.send('service_n29m8bl', 'template_h6d78rg', { name, sname, company, email, phone, message, country }, 'xfW0OXTCeM8pLNGG3')
            .then((result) => { setSubmitted('sent') }, (error) => { })
            .catch(r => console.log(`Sending form failed, email us instead through enquiries@datacorridor.io`))
    }

    return <>
        {/* <div>
            {JSON.stringify({ name, sname, company, email, phone, country, message })}
            <br />
            {JSON.stringify(valid ? 'yes' : 'no')}
        </div> */}
        <FormStateContext.Consumer>
            {(context) => (<>
                <Fade in={submitted === 'unsent'} mountOnEnter unmountOnExit timeout={{ ...transitTimeouts, enter: 1000 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <CssTextField style={{ width: '100%' }} label="First Name" variant='standard' onChange={handleNameChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <CssTextField style={{ width: '100%' }} label="Last Name" variant='standard' onChange={handleSnameChange} />
                        </Grid>

                        <Grid item xs={12}>
                            <CssTextField style={{ width: '100%' }} label="Company" variant='standard' onChange={handleCompanyChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <CssTextField style={{ width: '100%' }} label="Email" variant='standard' onChange={handleEmailChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <CssTextField style={{ width: '100%' }} label="Country" variant='standard' onChange={handleCountryChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <CssTextField style={{ width: '100%' }} label="Phone" variant='standard' onChange={handlePhoneChange} />
                        </Grid>
                        {/* <Grid item xs={12}>
            <CssTextField style={{width: '80%'}} label="Contact Reason" variant='standard' />
        </Grid> */}
                        <Grid item xs={12}>
                            <CssTextField style={{ width: '100%' }} label="Message" variant='standard' onChange={handleMessageChange} />
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12} />
                        <Grid item xs={12}>
                            <Button
                                disabled={!valid}
                                style={{ color: cyan(3), border: 'solid', borderRadius: '2px', minWidth: '120px', opacity: valid ? 1 : 0.2 }}
                                endIcon={<SendIcon />}
                                onClick={() => { sendEmail(); setSubmitted('pending') }}
                            >SUBMIT</Button>
                        </Grid>
                    </Grid>
                </Fade>

                <Fade in={submitted === 'sent'} mountOnEnter unmountOnExit timeout={{ ...transitTimeouts, enter: 1000 }}>
                    <p style={{ textAlign: 'justify', display: 'inline-block', ...fontStyle, paddingLeft: '30px', paddingRight: '30px', maxWidth: '700px', fontSize: `${props.height > props.width ? 16 : 18}px`, color: 'silver', opacity: 0.9 }}>Message sent, we will get back you soon!</p>
                </Fade>
            </>)}
        </FormStateContext.Consumer>
    </>
}

export default Forms;