import logo from './logo.svg';
import './App.css';
import { useEffect, useRef, useState } from 'react';
import Peer from 'peerjs';

// let peer = new Peer()
var peer = null;
// var stream = null


function Swarms() {

    const [initialised, setInitialised] = useState(false);
    const [peerId, setPeerId] = useState('');
    const [remotePeerIdValue, setRemotePeerIdValue] = useState('');
    const [connected, setConnected] = useState(false)
    const [vpnip, setVpnip] = useState('')
    const currentVideoRef = useRef();
    const remoteVideoRef = useRef();
    // var peer = useRef();
    var conn = useRef();
    const [datarecv, setDatarecv] = useState('');

    // const getData = () => {
    //     // create a new XMLHttpRequest
    //     var xhr = new XMLHttpRequest()

    //     // get a callback when the server responds
    //     xhr.addEventListener('load', () => {
    //         // update the state of the component with the result here
    //         console.log(xhr.responseText)
    //         setVpnip(xhr.responseText)
    //     })
    //     // open the request with the verb and the url
    //     xhr.open('GET', 'http://10.8.0.6/tun0ip')
    //     // send the request
    //     xhr.send()
    // }

    // async function getData(url = "", data = {}) {
    //     // Default options are marked with *
    //     const response = await fetch(url, {
    //         method: "GET", // *GET, POST, PUT, DELETE, etc.
    //         mode: "same-origin", // no-cors, *cors, same-origin
    //         cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    //         credentials: "same-origin", // include, *same-origin, omit
    //         headers: {
    //             "Content-Type": "text/plain",
    //             // 'Content-Type': 'application/x-www-form-urlencoded',
    //         },
    //         redirect: "follow", // manual, *follow, error
    //         referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //         //   body: data, // body data type must match "Content-Type" header
    //     });
    //     console.log(response)
    //     return response; // parses JSON response into native JavaScript objects
    // }

    // postData("http://10.8.0.6/tun0ip", { answer: 42 }).then((data) => {
    //     console.log(data); // JSON data parsed by `data.json()` call
    // });

    // useEffect(async () => getData("http://10.8.0.6/tun0ip"), [])

    const initPeer = (id) => {

        const options = { secure: false }
        peer = new Peer(id, options);

        peer.on('open', (id) => {
            // setPeerId(id);
            setConnected(true)
        })

        peer.on('connection', (cnn) => {
            // setDatarecv('connection') 
            conn = cnn;

            cnn.on('open', () => {

                console.log("conn opened");

                cnn.on('data', data => {
                    var recv = Date.now()
                    data = { ...data, recv }
                    setDatarecv(data);
                })

            })
        })


        peer.on('call', (call) => {
            // var getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

            var stream = navigator.getUserMedia =
                navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.msGetUserMedia;

            const streamHandler = (mediaStream) => {
                currentVideoRef.current.srcObject = mediaStream;
                currentVideoRef.current.play();

                call.answer(mediaStream)

                call.on('stream', (remoteStream) => {
                    remoteVideoRef.current.srcObject = remoteStream;
                    remoteVideoRef.current.play();
                })
            }

            const errorHandler = (err) => {
                console.log(err)
            }

            if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
                navigator.getUserMedia({
                    video: true,
                    audio: false
                }, streamHandler, errorHandler);
            } else {
                navigator.mediaDevices.getUserMedia({
                    video: true,
                    audio: false
                }).then(streamHandler).catch(errorHandler);
            }

            stream({ video: true },
            )
        })

        peer.on('close', () => {
            console.log('closing ssarms peer connection')

            if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
                const stream = currentVideoRef.current.srcObject;
                const tracks = stream.getTracks();

                tracks.forEach((track) => {
                    track.stop();
                });
            } else {
                const stream = currentVideoRef.current.srcObject;
                const tracks = stream.getTracks();

                tracks.forEach((track) => {
                    track.stop();
                });
            }
            currentVideoRef.current.srcObject = null;
        })

        console.log("swarms peer initialised")
        setInitialised(true)
        // peerInstance.current = peer;

    }

    const closeConnection = () => {
        peer.destroy()
        setConnected(false)
        // stream.getVideoTracks()[0].stop()
        // currentVideoRef.current.stop()
        // remoteVideoRef.current.stop()
    }

    const callRemote = (remotePeerId) => {
        var getUserMedia =
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia;

        conn.current = peer.connect(remotePeerId);

        getUserMedia({ video: true }, (mediaStream) => {

            currentVideoRef.current.srcObject = mediaStream
            currentVideoRef.current.play()

            const call = peer.call(remotePeerId, mediaStream);

            call.on('stream', (remoteStream) => {
                remoteVideoRef.current.srcObject = remoteStream;
                remoteVideoRef.current.play()
            })
        })
    }

    const sendData = () => {
        console.log("sending")
        var date = Date.now()
        conn.current.send({ strings: 'hi', sent: date })
    }

    // useEffect(() => {
    //     return () => {
    //         closeConnection()
    //     }
    // }, [])

    return (
        <div className="Swarms">
            <input type='text' disabled={connected} value={peerId} onChange={(e) => { setPeerId(e.target.value) }} />

            <button style={{ display: connected === false ? 'inline' : 'none' }} onClick={() => { console.log(peer); initPeer(peerId); console.log(peer) }}>Start with ID</button>
            <button style={{ display: connected === true ? 'inline' : 'none' }} onClick={() => { closeConnection() }}>Stop</button>
            <br />

            <div style={{ display: connected == '' ? 'none' : 'inline' }}>
                <input type='text' value={remotePeerIdValue} onChange={(e) => { setRemotePeerIdValue(e.target.value) }} />
                <button onClick={() => { callRemote(remotePeerIdValue) }}>Call</button>
                <button onClick={() => { sendData() }}>Send</button>
                <h1>{peerId}</h1>
                <div>
                    Remote
                    <video style={{ width: '250px' }} ref={remoteVideoRef} />
                </div>
                <div>
                    Current
                    <video style={{ width: '250px' }} ref={currentVideoRef} />
                </div>
                <div>{JSON.stringify(datarecv)}</div>
                {/* <div>{JSON.stringify(peer.current.id)}</div> */}
                {datarecv && <div>{JSON.stringify(datarecv.recv - datarecv.sent)}</div>}
            </div>
        </div>
    );
}

export default Swarms;
