import React from 'react';

export const BackgroundContext = React.createContext({
    frameloop: 'always',
    focusing: null,
    focuszoom: true,
    bgAnimate:'always'
})


// export const CtxProviderBackground = BackgroundContext.Provider;
// export const CtxConsumerBackground = BackgroundContext.Consumer;

